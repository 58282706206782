<template>
  <b-card v-if="doc && departamentos && plantillas && cabeceras && pies">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form class="p-2" @submit.prevent="handleSubmit(generate)">
        <b-row>
          <b-col>
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="titulo"
              rules="required"
            >
              <h5 class="font-weight-bold">Titulo</h5>
              <b-form-input
                v-model="doc.titulo"
                placeholder="Titulo"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <!-- Header: Plantilla -->
            <div class="d-flex mb-1 mt-2">
              <feather-icon icon="FileTextIcon" size="19" />
              <h4 class="mb-0 ml-50">Plantilla</h4>
            </div>

            <b-row align-h="between">
              <!-- Plantilla info -->
              <b-col cols="12" lg="12" md="12">
                <!-- Plantilla -->
                <validation-provider
                  #default="validationContext"
                  name="plantilla"
                  rules="required"
                >
                  <h5 class="font-weight-bold">Plantilla</h5>
                  <v-select
                    v-model="doc.plantilla"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="plantillas"
                    :clearable="false"
                    placeholder="Seleciona una plantilla"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <!-- Cabecera -->
                <h5 class="font-weight-bold mt-1">Cabecera</h5>
                <v-select
                  v-model="doc.cabecera"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cabeceras"
                  :clearable="false"
                  placeholder="Selecionar cabecera"
                />

                <!-- Pie -->
                <h5 class="font-weight-bold mt-1">Pie</h5>
                <v-select
                  v-model="doc.pie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pies"
                  :clearable="false"
                  placeholder="Selecionar pie"
                />

                <h5 class="font-weight-bold mt-1">Campos necessarios:</h5>
                <small v-if="getCampos().length === 0"
                  >No se requieren campos</small
                >
                <ul>
                  <li v-for="(campo, i) in getCampos()" :key="i">
                    {{ campo }}
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-col>
          <!-- Preview -->
          <b-col cols="4">
            <!-- Header: Vista Previa -->
            <div
              class="d-flex mt-2 mb-1 cursor-pointer"
              @click="previo = !previo"
            >
              <feather-icon v-if="previo" icon="EyeIcon" size="19" />
              <feather-icon v-else icon="EyeOffIcon" size="19" />
              <h4 class="mb-0 ml-50">Vista Previa</h4>
            </div>
            <div>
              <!-- Ckeditor -->
              <div
                v-if="previo"
                class="ckeditor-container"
                style="border-style: solid"
              >
                <ckeditor
                  v-model="docPreview"
                  :editor="editorType"
                  :config="editorConfig"
                  @ready="onEditorReady"
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- Header: Empleado -->
        <div class="d-flex mt-2 mb-1">
          <feather-icon icon="FileTextIcon" size="19" />
          <h4 class="mb-0 ml-50">Empleados</h4>
        </div>

        <!-- Contrato -->
        <b-row align-h="between">
          <b-col md="2">
            <h5 class="font-weight-bold">Contrato desde</h5>
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="doc.inicio_contrato"
                placeholder="dd-mm-yyyy"
                class="form-control"
                :config="{
                  maxDate: doc.fin_contrato,
                  format: 'd-m-Y',
                  altFormat: 'd-m-Y',
                  allowInput: true,
                  altInput: true,
                  locale: Spanish,
                }"
              />
            </div>
          </b-col>
          <b-col md="2">
            <h5 class="font-weight-bold">Contrato hasta</h5>
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="doc.fin_contrato"
                placeholder="dd-mm-yyyy"
                class="form-control"
                :config="{
                  minDate: doc.inicio_contrato,
                  format: 'd-m-Y',
                  altFormat: 'd-m-Y',
                  allowInput: true,
                  altInput: true,
                  locale: Spanish,
                }"
              />
            </div>
          </b-col>
          <b-col sm="4">
            <h5 class="font-weight-bold">Departamento</h5>
            <v-select
              v-model="doc.departamento"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="departamentos"
              :clearable="false"
              placeholder="Selecionar Departamento"
              class="mb-1"
            />
          </b-col>

          <!-- Buscar empleados -->
          <b-col sm="2">
            <h5 class="font-weight-bold">Buscar</h5>
            <b-form-input
              v-model="searchQuery"
              placeholder="Buscar por nombre, apellido o matrícula..."
            />
          </b-col>
          <b-col sm="2" class="mt-1">
            <b-button
              v-if="!canSearchForEmpleados"
              style="width: 200px; margin-top: 11px"
              class="mr-1"
              variant="outline-primary"
              @click="buscarEmpleados()"
              >Buscar Empleados
              <feather-icon icon="SearchIcon" />
            </b-button>
            <b-button
              v-else
              class="mt-1 mr-1 btn-icon"
              variant="outline-primary"
              @click="buscarEmpleados()"
              ><feather-icon icon="SearchIcon" />
            </b-button>

            <b-button
              v-if="canSearchForEmpleados"
              class="mt-1"
              variant="outline-danger"
              @click="clearEmpleadoFilter()"
            >
              Limpiar
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>
        </b-row>

        <b-table
          ref="refEmpleadoListTable"
          class="mt-2"
          selectable
          :items="empleados"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          empty-text="No se encontraron registros coincidentes"
          :busy="!empleados.length"
          @row-clicked="onRowClicked"
        >
          <!-- Table Loading -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" small />
              <strong class="ml-1">Cargando datos...</strong>
            </div>
          </template>

          <!-- Checkbox -->
          <template #head(select)="">
            <b-form-checkbox v-model="selects" @change="selectAllRows">
              <span>
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
            </b-form-checkbox>
          </template>

          <!-- Checkbox -->
          <template #cell(select)="data">
            <b-form-checkbox
              v-model="data.item.isSelected"
              style="cursor: pointer; pointer-events: none"
            >
              <span>
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
            </b-form-checkbox>
          </template>

          <template #cell(apellidos)="data">
            {{ data.item.apellidos }}, {{ data.item.nombre }}
          </template>

          <template #cell(inicio_contrato)="data">
            {{
              data.item.inicio_contrato
                ? parseDate(data.item.inicio_contrato)
                : "-"
            }}
          </template>

          <template #cell(fin_contrato)="data">
            {{
              data.item.fin_contrato ? parseDate(data.item.fin_contrato) : "-"
            }}
          </template>

          <template #cell(fk_dpto)="data">
            {{ getDptoName(data.item.fk_dpto) }}
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
                {{ dataMeta.of }} entradas</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalEmpleados"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <b-row class="mx-0 mt-2">
          <strong v-if="requireEmpleados" style="color: red"
            >Se requieren empleados!</strong
          >
        </b-row>
        <!-- Action buttons -->
        <b-row align-h="end" class="mr-0">
          <b-button class="mr-1" variant="outline-secondary" @click="clear()"
            >Resetar</b-button
          >
          <b-button variant="primary" type="submit">
            <span v-if="loading" class="pr-1">Generando</span>
            <span v-else> Generar</span>
            <b-spinner v-if="loading" small />
          </b-button>
          <b-button
            v-if="showGenerarButton"
            variant="primary"
            type="submit"
            style="position: fixed; top: 30px; right: 12%; z-index: 4444"
          >
            <span v-if="loading" class="pr-1">Generando</span>
            <span v-else>
              Generar con
              {{
                doc.empleados.length == 1
                  ? `${doc.empleados.length} empleado`
                  : `${doc.empleados.length} empleados`
              }}</span
            >
            <b-spinner v-if="loading" small />
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
  <Loader v-else />
</template>

<script>
// Form validators

import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";

import { onUnmounted } from "@vue/composition-api";
import { Spanish } from "flatpickr/dist/l10n/es";
import store from "@/store";
import departamentoStoreModule from "@/views/system/departamento/departamentoStoreModule";
import empleadoStoreModule from "@/views/system/empleado/empleadoStoreModule";
import useEmpleadosList from "@/views/system/empleado/useEmpleadosList";
import docStoreModule from "@/views/diseno/docs/docsStoreModule";
import useDepartamentosList from "@/views/system/departamento/useDepartamentosList";
import usePlantillasList from "@/views/diseno/plantillas/usePlantillasList";
import plantillaStoreModule from "@/views/diseno/plantillas/plantillasStoreModule";
import Loader from "@/components/Loader.vue";

import ckConfig from "@/middlewares/ckConfig";
import { avaiableKeys } from "@/views/diseno/plantillas/helper";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";

import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormInput,
  BPagination,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormInput,
    BPagination,
    BForm,
    BFormInvalidFeedback,

    flatPickr,
    vSelect,
    Loader,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    ckeditor: CKEditor.component,
  },
  data() {
    return {
      Spanish,
      avaiableKeys,
      editor: null,
      previo: false,
      editorType: CustomEditor,
      editorConfig: {
        fontColor: ckConfig.fontColor,
        fontBackgroundColor: ckConfig.fontBackgroundColor,
      },
      loading: false,
      showGenerarButton: false,
      required,
      requireEmpleados: false,
      doc: {
        plantilla: null,
        cabecera: null,
        pie: null,
        inicio_contrato: null,
        fin_contrato: null,
        departamento: null,
        empleados: null,
      },
      departamentos: null,
      plantillas: null,
      selects: false,
      cabeceras: null,
      pies: null,
      canSearchForEmpleados: false,
      tableColumns: [
        { key: "select", label: "Seleccione", sortable: false },
        { key: "matricula", label: "matricula", sortable: true },
        { key: "apellidos", label: "Nombre", sortable: true },
        { key: "dni", label: "DNI", sortable: false },
        { key: "fk_dpto", label: "Departamento", sortable: true },
        { key: "inicio_contrato", label: "Inicio Contr.", sortable: true },
        { key: "fin_contrato", label: "Fin Contr.", sortable: true },
      ],
    };
  },
  computed: {
    docPreview: {
      // getter
      get() {
        return `${this.doc.cabecera?.body_html ?? ""}${
          this.doc.plantilla?.body_html ?? ""
        }${this.doc.pie?.body_html ?? ""}`;
      },
      // setter
      set() {},
    },
    user() {
      return this.$store.state.auth.user;
    },
    empleados() {
      const empleadosFromState = this.$store.state["app-empleado"].empleados;

      if (this.doc.empleados !== null) {
        this.doc.empleados.forEach((emp) => {
          const index = empleadosFromState.findIndex((ob) => ob.id === emp.id);
          if (index !== -1) {
            store.commit("app-empleado/SET_EMPLEADO_AS_SELECTED", {
              index,
              value: true,
            });
            this.$refs.refEmpleadoListTable.selectRow(index);
          }
        });
      }

      // check if all rows are selected
      /* eslint-disable-next-line vue/no-side-effects-in-computed-properties */
      this.selects = true;
      empleadosFromState.forEach((e) => {
        if (!e.isSelected) this.selects = false;
      });

      return empleadosFromState;
    },
  },
  watch: {
    currentPage() {
      this.selects = false;
    },
    doc: {
      handler(val) {
        if (val.inicio_contrato || val.fin_contrato || val.departamento) {
          this.canSearchForEmpleados = true;
        }

        if (val.empleados) this.requireEmpleados = false;
      },
      deep: true,
    },
  },
  async created() {
    this.departamentos = await this.listDepartamentos();
    const fullPlantillas = await this.listPlantillas();
    this.plantillas = fullPlantillas.plantillas;
    this.cabeceras = fullPlantillas.cabeceras;
    this.pies = fullPlantillas.pies;
  },
  methods: {
    onEditorReady(editor) {
      this.editor = editor;
      this.editor.isReadOnly = true;
    },
    enablePreview() {
      this.previo = !this.previo;
    },
    selectAllRows() {
      if (this.selects) {
        // select all rows of table and set state of empleados to selected
        this.$refs.refEmpleadoListTable.selectAllRows();
        store.commit("app-empleado/SET_ALL_EMPLEADOS_SELECTED_STATUS", true);
        if (!this.doc.empleados) this.doc.empleados = [];
        // concat array without duplicates
        this.doc.empleados = [
          ...new Set([...this.doc.empleados, ...this.empleados]),
        ];
      } else {
        // unselec all rows of table and set state of empleados to unselected
        this.$refs.refEmpleadoListTable.clearSelected();
        this.empleados.forEach((em, i) => {
          this.$refs.refEmpleadoListTable.unselectRow(i);
          if (this.doc.empleados) {
            const index = this.doc.empleados.findIndex((ob) => ob.id === em.id);
            if (index !== -1) {
              this.doc.empleados.splice(index, 1);
            }
          }
        });

        store.commit("app-empleado/SET_ALL_EMPLEADOS_SELECTED_STATUS", false);
      }
    },
    getDptoName(fk_dpto) {
      return this.departamentos.find((el) => el.key === fk_dpto)?.label ?? "-";
    },
    clearEmpleadoFilter() {
      this.showGenerarButton = false;
      this.canSearchForEmpleados = !this.canSearchForEmpleados;
      this.doc.inicio_contrato = null;
      this.doc.fin_contrato = null;
      this.doc.departamento = null;
      this.searchQuery = null;
      this.clearFilter();
    },
    getCampos() {
      let campos = [];
      if (this.doc.plantilla) {
        if (this.doc.plantilla.campos) {
          const cs = this.doc.plantilla.campos.split(",");
          campos = campos.concat(cs);
        }
      }
      if (this.doc.cabecera) {
        if (this.doc.cabecera.campos) {
          const cs = this.doc.cabecera.campos.split(",");
          campos = campos.concat(cs);
        }
      }
      if (this.doc.pie) {
        if (this.doc.pie.campos) {
          const cs = this.doc.pie.campos.split(",");
          campos = campos.concat(cs);
        }
      }
      return campos;
    },
    clear() {
      this.doc = {
        plantilla: null,
        cabecera: null,
        pie: null,
        inicio_contrato: null,
        fin_contrato: null,
        departamento: [],
        empleados: null,
      };

      // unselec all rows of table and set state of empleados to unselected
      this.$refs.refEmpleadoListTable.clearSelected();
      this.empleados.forEach((em, i) => {
        this.$refs.refEmpleadoListTable.unselectRow(i);
        if (this.doc.empleados) {
          const index = this.doc.empleados.findIndex((ob) => ob.id === em.id);
          if (index !== -1) {
            this.doc.empleados.splice(index, 1);
          }
        }
      });

      store.commit("app-empleado/SET_ALL_EMPLEADOS_SELECTED_STATUS", false);
    },
    buscarEmpleados() {
      console.log('buscarEmpleados called');
      
      this.filterInicioContrato = this.doc.inicio_contrato;
      this.filterFinContrato = this.doc.fin_contrato;
      this.filterDepartamento = this.doc.departamento;
      this.filterData();
    },
    onRowClicked(sItem) {
      if (!this.doc.empleados) this.doc.empleados = [];

      const index = this.doc.empleados.findIndex((ob) => ob.id === sItem.id);
      const indexOnTable = this.empleados.findIndex((ob) => ob.id === sItem.id);

      if (index !== -1) {
        this.doc.empleados.splice(index, 1);
        store.commit("app-empleado/SET_EMPLEADO_AS_SELECTED", {
          index: indexOnTable,
          value: false,
        });
        this.$refs.refEmpleadoListTable.selectRow(indexOnTable);
      } else {
        this.doc.empleados.push(sItem);
        store.commit("app-empleado/SET_EMPLEADO_AS_SELECTED", {
          index: indexOnTable,
          value: true,
        });
        this.$refs.refEmpleadoListTable.unselectRow(indexOnTable);
      }
      // eslint-disable-next-line no-unused-expressions
      this.doc.empleados.length >= 1
        ? (this.showGenerarButton = true)
        : (this.showGenerarButton = false);
    },
    generate() {
      if (this.doc.empleados && this.doc.empleados.length > 0) {
        this.loading = true;
        const empl = [];
        this.doc.empleados.forEach((element) => {
          empl.push(element.id);
        });

        const req = {
          fk_logo: this.doc.cabecera?.key ?? null,
          fk_contenido: this.doc.plantilla.key,
          fk_pie: this.doc.pie?.key ?? null,
          empleados: empl.toString(),
          titulo: this.doc.titulo,
          file_name: this.doc.titulo,
          fk_user: this.user.id,
        };

        this.$store
          .dispatch("app-doc/addDoc", req)
          .then(() => {
            this.$router.push({ name: "docs-list" });
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else this.requireEmpleados = true;
    },
  },
  setup() {
    const DEPARTAMENTO_APP_STORE_MODULE_NAME = "app-departamento";
    const EMPLEADO_APP_STORE_MODULE_NAME = "app-empleado";
    const PLANTILLA_APP_STORE_MODULE_NAME = "app-plantilla";
    const DOC_APP_STORE_MODULE_NAME = "app-doc";

    const blankDoc = {
      plantilla: null,
      cabecera: null,
      pie: null,
      inicio_contrato: null,
      fin_contrato: null,
      departamento: null,
      empleados: null,
    };

    // Register module
    if (!store.hasModule(DOC_APP_STORE_MODULE_NAME))
      store.registerModule(DOC_APP_STORE_MODULE_NAME, docStoreModule);

    if (!store.hasModule(DEPARTAMENTO_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        DEPARTAMENTO_APP_STORE_MODULE_NAME,
        departamentoStoreModule
      );
    }

    if (!store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME))
      store.registerModule(EMPLEADO_APP_STORE_MODULE_NAME, empleadoStoreModule);

    if (!store.hasModule(PLANTILLA_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PLANTILLA_APP_STORE_MODULE_NAME,
        plantillaStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOC_APP_STORE_MODULE_NAME))
        store.unregisterModule(DOC_APP_STORE_MODULE_NAME);

      if (store.hasModule(DEPARTAMENTO_APP_STORE_MODULE_NAME))
        store.unregisterModule(DEPARTAMENTO_APP_STORE_MODULE_NAME);

      if (store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME))
        store.unregisterModule(EMPLEADO_APP_STORE_MODULE_NAME);

      if (store.hasModule(PLANTILLA_APP_STORE_MODULE_NAME))
        store.unregisterModule(PLANTILLA_APP_STORE_MODULE_NAME);
    });

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(blankDoc);

    const {
      // fetchEmpleados,
      fetchEmpleadosToState,
      isEmpleadoState,
      perPage,
      currentPage,
      totalEmpleados,
      dataMeta,
      sortBy,
      filterInicioContrato,
      filterFinContrato,
      refEmpleadoListTable,
      filterDepartamento,
      searchQuery,
      filterData,
      isSortDirDesc,
      clearFilter,
      parseDate,
    } = useEmpleadosList();

    const { listDepartamentos } = useDepartamentosList();
    const { listPlantillas } = usePlantillasList();

    fetchEmpleadosToState();
    isEmpleadoState.value = true;

    return {
      // fetchEmpleados,
      fetchEmpleadosToState,
      perPage,
      currentPage,
      totalEmpleados,
      dataMeta,
      sortBy,
      listDepartamentos,
      listPlantillas,
      filterInicioContrato,
      filterFinContrato,
      filterDepartamento,
      clearFilter,
      refEmpleadoListTable,
      filterData,
      isSortDirDesc,
      refFormObserver,
      getValidationState,
      resetForm,
      searchQuery,
      parseDate,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
