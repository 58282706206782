<template>
  <div>
    <div class="text-center text-primary my-2">
      <b-spinner class="align-middle" />
      <strong class="ml-1">Cargando datos...</strong>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
}
</script>
